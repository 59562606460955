/* colours */
:root {
  --primary: #7acdf0;
  --secondary: #90d7f5;
  --title: #ff8816;
}

#root {
  min-width: 375px;
}

a {
  cursor: pointer;
  color: #000;
}

h6 {
  font-size: 1.3rem;
}
select {
  display: inherit;
}
.toast {
  margin: 0;
  top: 0;
  min-height: 36px;
}

.App {
  margin-top: 10px;
  font-size: 16px;

  .container {
    width: 95%;
    padding-top: 44px;
  }
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.mood_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
}

.mood_grid svg {
  width: 100%;
  height: auto;
}

.btn,
.btn:focus {
  background-color: #17ca24;
}

.addpoo {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  justify-items: center;
  column-gap: 1vw;

  #addpoo_icon {
    width: 100%;
    height: auto;
  }

  #moodypoosvg {
    width: 100%;
    height: auto;
  }

  #addpoo_shapes,
  #addpoo_colors {
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    width: 100%;

    svg {
      width: 100%;
      height: auto;
    }
  }

  #pootext {
    text-align: center;
  }
}

#poo_results_seven {
  text-align: center;
  margin-top: 3em;
  margin-bottom: 1em;
  font-size: 14px;
}

.poo_result_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  column-gap: 1vw;

  text-align: center;
  border-left: solid 1px grey;
  border-bottom: solid 1px grey;
  padding: 5px;

  svg {
    width: 100%;
    height: auto;
  }

  .btn-floating {
    border-radius: 0;
    background-color: inherit;
    border: 0;
    box-shadow: none;
    width: 100%;
    height: auto;
    line-height: inherit;

    & :hover {
      background-color: #fff;
    }
  }
}

.poo_result_grid_labels {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  column-gap: 1vw;

  text-align: center;
  padding: 0 5px;
}

#info {
  .infoGrid {
    display: grid;
    grid-template-columns: 1fr 5fr;
    justify-items: left;
    align-items: start;
    row-gap: 1em;
    margin-bottom: 1em;

    p {
      margin: 0;
      align-self: stretch;
    }
  }
}

.linkContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  background-color: #fff;
  z-index: 9999;
  top: 0;
  padding: 5px 10px 0 10px;
  border-bottom: 1px solid #e0e0e0;

  .material-icons.big {
    font-size: 32px;
  }

  .menu-icon {
    margin-top: 3px;
  }

  #logo {
    margin-top: 3px;
  }

  h6 {
    margin: 5px 0;
  }
}

.material-icons {
  &.big {
    font-size: 28px;
  }
}

#results {
  .result_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    column-gap: 1vw;

    text-align: center;
    padding: 5px;

    > a {
      width: 100%;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  .result_grid_labels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    column-gap: 1vw;
    border-top: solid 1px grey;

    text-align: center;
    padding: 0 5px;
  }
}

#modalInfo {
  #editDelete {
    position: absolute;
    bottom: 0;
    margin-bottom: 17px;

    > a {
      margin-right: 5px;

      + a {
        margin-left: 5px;
      }
    }
  }

  .modal-content {
    > h4 {
      display: none;
    }

    #modalInfo--PooInfo {
      display: grid;
      grid-template-columns: 1fr 5fr;
      column-gap: 3vw;

      .modalInfo {
        margin-top: 5px;

        div {
          margin-top: 5px;
        }
        .modalInfo--header {
          margin-top: 0;
          display: flex;
          justify-content: space-between;
          .modalInfo--header-title {
            font-size: 14px;
          }
          > div {
            margin-top: 0;
          }
        }
      }

      svg {
        width: 100%;
      }
    }
  }
}

.buttonsSubmit {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;

  > a {
    width: 49%;
    min-height: 36px;
  }

  .toast {
    width: 100%;
  }

  form {
    width: 49%;
  }
}

#detailIcons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  column-gap: 1vw;
  margin-bottom: 3em;

  svg {
    width: 100%;
    height: auto;
  }

  > div {
    text-align: center;
    width: 100%;
  }

  textarea {
    outline: none;
  }

  .detailIcons--red {
    .strokeRed {
      stroke: red;
    }
  }
}

.loader svg {
  margin: 0 auto;
  width: 100%;
  margin-top: 5em;
}

.moodypoo_small {
  width: 40px;
  height: 40px;
}

#lang {
  width: 70%;
}

.details {
  .container {
    padding-top: 0;
  }

  .linkContainer + .container {
    margin-top: 44px;
  }

  .details--header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    justify-items: center;
    column-gap: 1vw;

    svg {
      margin-top: 10px;
      width: 80%;
    }
    .details--header-title--text {
      margin-top: 10px;
      text-align: center;
    }

    .details--weather {
      img {
        width: 100%;
      }
      .details--weather-temp {
        text-align: center;
        margin-top: -25px;
        font-size: 12px;
      }
    }
  }
}

.sidenav {
  & .user-view {
    padding: 16px 16px 0;
    & .name {
      padding-bottom: 16px;
    }
  }
}

.dashboardContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  margin-bottom: 1em;

  .chart3 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .chart4 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;

    .chart3 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
    }
    .chart4 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 4;
      grid-row-end: 5;
    }
  }
}

.settings {
  &.collection {
    padding-top: 44px;
  }
}
